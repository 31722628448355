// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-education-content-js": () => import("./../../../src/templates/education-content.js" /* webpackChunkName: "component---src-templates-education-content-js" */),
  "component---src-templates-education-js": () => import("./../../../src/templates/education.js" /* webpackChunkName: "component---src-templates-education-js" */),
  "component---src-templates-frontpage-js": () => import("./../../../src/templates/frontpage.js" /* webpackChunkName: "component---src-templates-frontpage-js" */),
  "component---src-templates-podcasts-js": () => import("./../../../src/templates/podcasts.js" /* webpackChunkName: "component---src-templates-podcasts-js" */),
  "component---src-templates-register-js": () => import("./../../../src/templates/register.js" /* webpackChunkName: "component---src-templates-register-js" */),
  "component---src-templates-working-group-js": () => import("./../../../src/templates/working-group.js" /* webpackChunkName: "component---src-templates-working-group-js" */)
}

